<template>
  <div class="subscription-wrapper">
    <div class="subscription-title">
      {{ $t('subscription_manager.title') }}
    </div>
    <div class="subscription-info">
      <div class="subscription-logo__wrap">
        <img :src="accountLogo" alt="">
      </div>
      <div class="subscription-info__item">
        <div class="subscription-info__label">{{ $t('subscription_manager.account_version') }}</div>
        <div class="subscription-info__value">{{ version }}</div>
      </div>
      <div class="subscription-info__item" v-if="data.account_type !== 'free'">
        <div class="subscription-info__label">{{ $t('subscription_manager.start_time') }}</div>
        <div class="subscription-info__value">{{data.service_opening_time || $t('tip.unknow')}}</div>
      </div>
      <div class="subscription-info__item" v-if="data.account_type !== 'free'">
        <div class="subscription-info__label">{{ $t('subscription_manager.end_time') }}</div>
        <div class="subscription-info__value">{{data.service_expiration_time || $t('tip.unknow')}}</div>
      </div>
      <div class="subscription-info__item action-wrap">
        <div class="subscription-upgrade__btn" v-show="data.account_type === 'free' || data.account_type === 'professional'" @click="upgrade">{{ $t('subscription_manager.upgrade') }}</div>
        <div class="subscription-renew__btn" v-show="data.account_type === 'enterprise' || data.account_type === 'professional'" @click="renew">{{ $t('subscription_manager.renew') }}</div>
      </div>
    </div>
    <div class="subscription-content">
      <price-table :active="data.account_type" />
    </div>
    <to-order-dialog @closeDialog="closeToOrderDialog" v-if="showToOrderLog" />
  </div>
</template>

<script setup>
import { onMounted, computed, ref, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import priceTable from './components/price-table.vue'
import toOrderDialog from '@/components/order/dialog.vue'
import { getPaymentSubscribe, getPaymentOrderCheck } from '@/services/order.js'

const { appContext: { config: { globalProperties } } } = getCurrentInstance()

const permiseMap = {
  free: globalProperties.$t('UserGroup.free'),
  enterprise: globalProperties.$t('UserGroup.enterprise'),
  professional: globalProperties.$t('UserGroup.professional'),
  qihoo: '奇虎版'
}
const store = useStore()
const router = useRouter()
const data = ref({})
const gProperties = getCurrentInstance().appContext.config.globalProperties
const accountLogo = computed(() => {
  if (store.getters.userInfo.user_pic && store.getters.userInfo.user_pic.type && store.getters.userInfo.user_pic.base64) {
    return `data:image/${store.getters.userInfo.user_pic.type};base64,${store.getters.userInfo.user_pic.base64}`
  }
  return gProperties.$isDark() ? require('@/assets/img/avatar.png') : require('@/assets/img/avatar-light.png')
})

const showToOrderLog = ref(false)
const version = computed(() => {
  return permiseMap[data.value.account_type] || data.value.account_type
})

onMounted(() => {
  getData()
})

const getData = () => {
  getPaymentSubscribe().then(res => {
    data.value = res
  })
}

// 如果点叉就只关弹窗，否则就跳转订单页
const closeToOrderDialog = (flag) => {
  showToOrderLog.value = false
  if (!flag) return
  router.push({
    name: 'order'
  })
}

const renew = () => {
  getPaymentOrderCheck().then(res => {
    if (res.status) {
      // this.showLog = true
      showToOrderLog.value = true
      return
    }
    // this.showLog = false
    showToOrderLog.value = false
    router.push({ name: 'subscribe', query: { type: 'renew', group: data.value.account_type } })
  })
}

const upgrade = () => {
  getPaymentOrderCheck().then(res => {
    if (res.status) {
      // this.showLog = true
      showToOrderLog.value = true
      return
    }
    // this.showLog = false
    showToOrderLog.value = false
    const query = {
      type: '',
      group: data.value.account_type
    }
    if (data.value.account_type === 'professional') {
      query.group = 'enterprise'
      query.type = 'upgrade'
    } else {
      query.type = 'purchase'
      query.group = 'professional'
    }
    router.push({ name: 'subscribe', query })
  })
}

</script>
<style lang="scss" scoped>
[data-theme='light'] {
  .subscription-upgrade__btn {
    color: #DF9E31 !important;
  }
}
.subscription-wrapper {
  padding: 0 24px;
  padding-bottom: 24px;
  min-height: calc(100% - 60px);
  .subscription-title {
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    color: var(--color-text-1);
  }
  .subscription-info {
    height: 180px;
    background: var(--detect-config-bg);
    border-radius: 2px;
    box-sizing: border-box;
    padding: 24px;
    .subscription-logo__wrap {
      padding: 3px 56px 9px 20px;
      float: left;
      &>img {
        width: 120px;
        border-radius: 60px;
      }
    }
    .subscription-info__item {
      float: left;
      margin-top: 39px;
      height: 54px;
      text-align: center;
      border-left: 1px solid var(--color-border-2);
      width: 210px;
      .subscription-info__label {
        color: var(--color-text-3);
      }
      .subscription-info__value {
        margin-top: 22px;
        color: var(--color-text-2);
      }
      &.action-wrap {
        min-width: 217px;
        box-sizing: border-box;
        padding: 0 11px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .subscription-renew__btn {
          cursor: pointer;
          width: 88px;
          height: 32px;
          color: #5e3b17;
          background: linear-gradient(76deg, #f3d8aa 0%, #eabb6d 100%);
          border-radius: 16px;
          line-height: 32px;
        }
        .subscription-upgrade__btn {
          color: #f3d7a7;
          width: 88px;
          height: 32px;
          cursor: pointer;
          border: 1px solid #f3d7a7;
          border-radius: 16px;
          line-height: 32px;
        }
      }
    }
  }
  .subscription-content {
    background: var(--detect-config-bg);
    border-radius: 2px;
    // margin-bottom: 30px;
    margin-top: 15px;
    padding: 24px;
  }
}
</style>
