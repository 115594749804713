<template>
  <div class="plan-wrap">
    <div class="plan-table-wrap">
      <div class="plan-item-shadow" ref="planShadow"></div>
      <div
        v-for="(item, index) in priceInfo"
        :key="index"
        :class="['plan-item', {title: item.title}, {header: item.html}]"
      >
        <template v-if="item.html">
          <span class="plan-item-col title" ref="planItem">

          </span>
          <span class="plan-item-col free" :class="{'plan-item-col-active': 'free' === active}">
            <span class="plan-type">{{ $t('UserGroup.free') }}</span>
          </span>
          <span class="plan-item-col professional" :class="{'plan-item-col-active': 'professional' === active}">
            <span class="plan-type">{{ $t('UserGroup.professional') }}</span>
          </span>
          <span class="plan-item-col enterprise" :class="{'plan-item-col-active': 'enterprise' === active}">
            <span class="plan-type">{{ $t('UserGroup.enterprise') }}</span>
          </span>
        </template>
        <template v-else>
          <span class="plan-item-col title">
            <span :class="{ rowTitle: !item.desc }">{{item.name}}</span>
            <span class="info">
              <el-tooltip :popper-append-to-body="false" popper-class="plan-info-tips" :effect="$isDark() ? 'dark' : 'light'" :content="item.desc" placement="right-end">
                <img src="@/assets/img/svg/info.svg" alt="" />
              </el-tooltip>
            </span>
          </span>
          <span
            v-for="role in rolelist"
            :key="role"
            :class="{
              'plan-item-col': true,
              role: true,
              'plan-item-col-active': role === active,
            }">
            <span v-if="(typeof item[role]) !== 'boolean'" class="desc">{{item[role]}}</span>
            <span v-else class="pic"><img :src="getStatus(item[role])" /></span>
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import priceInfo from '@/views/price/config.js'
import { ref, defineProps, onMounted } from 'vue'

const planShadow = ref()
const planItem = ref()
const rolelist = [
  'free',
  'professional',
  'enterprise'
]

const props = defineProps({
  active: {
    type: String
    // default: 'free'
  }
})

onMounted(() => {
  setTimeout(() => {
    const width = planItem.value[0].offsetWidth
    const roleIndex = rolelist.findIndex(item => item === props.active)
    const left = width * (roleIndex + 1)
    planShadow.value.style.width = width + 'px'
    planShadow.value.style.left = left + 'px'
  }, 480)
})

const getStatus = (type) => {
  const src = type ? 'checked' : 'unchecked'
  return require(`@/assets/img/svg/${src}.svg`)
}
</script>

<style lang="scss" scoped>
[data-theme='light'] {
  .plan-wrap .plan-table-wrap .plan-item.title {
    // background: #f8f9f2;
  }
}

.plan-wrap {
  margin-left: auto;
  margin-right: auto;
  .plan-table-wrap {
    position: relative;
    .plan-item {
      line-height: 48px;
      font-size: 14px;
      display: flex;
      border-bottom: 1px solid var(--color-border-2);
      &.header {
        .plan-item-col {
          color: var(--color-text-1);
          background-color: var(--color-bg-4);
        }
      }
      .plan-item-col {
        display: inline-flex;
        width: 25%;
        // background: var(--detect-config-bg);
        box-sizing: border-box;
        padding: 0 20px;
        overflow: hidden;
        color: var(--color-text-2);
        .plan-type {
          font-weight: 600;
        }
        &:not(:first-child) {
          text-align: center;
          justify-content: center;
          align-items: center;
        }
        &.title {
          position: relative;
          color: var(--color-text-2);
          .rowTitle {
            color: var(--color-text-2);
            font-weight: 600;
          }
          .info {
            display: none;
            position: absolute;
            right: 11px;
            top: 0;
            bottom: 0;
          }
        }
      }
      &.title {
        background: var(--color-bg-3);
        box-shadow: 0 -1px 0 0 var(--color-border-2);
      }
    }
  }
  .plan-item-shadow {
    width: 307px;
    height: 100%;
    position: absolute;
    left: 307px;
    top: 0;
    bottom: 0;
    // box-shadow: -2px 2px 4px rgba(0,0,0,.3);
  }
}
</style>
